import { en } from 'vuetify/src/locale';
import profile from '@/locales/en/profile';
import onboarding from '@/locales/en/onboarding';
import subscription from '@/locales/en/subscription';
import validators from '@/locales/en/validators';
import mappings from '@/locales/en/mappings';

export default {
  ...en,
  profile,
  onboarding,
  subscription,
  validators,
  mappings,
  views: {
    Admin: 'Admin',
    Portfolio: 'My Portfolio',
    Marketplace: 'Investments',
    Settings: 'Settings',
    Support: 'Support',
    Logout: 'Logout',
    NewDealsProduct: 'New Deals',
    DigitalAssets: 'Digital Assets',
    Banking: 'Banking',
    Transactions: 'Transactions',
    SignIn: 'Login',
    SignOut: 'Sign out',
    CreateAsset: 'Create Asset',
    ShowAsset: 'Asset Details',
    ShowDocument: 'Document Details',
    Notifications: 'Notifications',
    Subscription: 'Subscription',
    Profile: {
      Index: 'Profile',
      Support: 'Support',
      Notifications: 'Notification Settings',
      ChangePassword: 'Change password',
      LegalInformation: 'Legal Information',
      BillingContracts: 'Billing & Contracts',
      DeleteAccount: 'Delete Account',
      title: 'My Space',
      liquidity: 'Liquidity',
      deposit: 'Deposit money',
      pay: 'Withdraw money',
      documents: 'Activities & Documents',
    },
    Deposit: {
      title: 'Deposit',
      subtitle: 'Deposit money',
      desc: 'Die folgenden Schritte erklären, wie Du auf Deinen COMETUM-Account Geld einzahlen kannst.<br><ol><li>Oben in der Karte findest Du eine IBAN und eine Payment-ID.</li><li>Um Geld einzuzahlen, überweise einen Betrag an dieses Konto und gib in der Überweisung die Payment-ID als Verwendungszweck an.</li></ol>',
    },
    Withdraw: {
      title: 'Withdraw',
      amount: {
        question: 'How much do you want to withdraw?',
        currentBalance: 'Current account balance',
        desiredAmount: 'Desired payout amount',
        inputLabel: 'Amount in EUR',
        next: 'Next',
      },
      confirm: {
        desc: (ctx) => `Confirm your withdrawal of ${ctx.named('amount')} to the reference bank account.`,
        btn: 'Withdraw now',
        info: 'The payout usually takes no more than five working days.',
      },
      finish: {
        desc: (ctx) => `Payment of ${ctx.named('amount')} successfully requested.`,
        btn: 'Break up',
      },
    },
    terms: 'Privacy Notices',
    termsPDF: 'Privacy-Notices.pdf',
    privacy: 'Privacy Policy',
    notice: 'Legal Notice',
    Onboarding: {
      RegistrationProcessOverview: 'State of Registration',
      personal: {
        GeneralInformation: 'General Information',
        PersonalData: 'Personal Information',
        ContactData: 'Address Information',
        IdentificationData: 'Identification Information',
        TaxInformation: 'Tax Information',
        BankAccountInformation: 'Bank Account',
        SourceOfWealthInformation: 'Source Of Funds',
        Overview: 'Data Overview',
      },
      classification: {
        Acknowledgement: 'Investor Profile',
        Conditions: 'Investor Profile',
        InvestorClassification: 'Investor Profile',
        Overview: 'Investor Profile',
      },
      investmentSurvey: {
        Intro: 'Knowledge & Experiences',
        Products: 'Knowledge & Experiences',
        Services: 'Knowledge & Experiences',
        Overview: 'Knowledge & Experiences',
      },
      videoIdent: {
        Information: 'Video Identification',
        Selection: 'Video Identification',
      },
      contractClosing: {
        Overview: 'Contract Signature',
      },
      investorApproval: {
        VideoIdent: 'Video Identification',
        MinimumDeposit: 'Minimum Deposit',
      },
    },
  },
  a2hs: {
    button: 'Install App',
    message: 'Install the App for the best experience',
  },
  components: {
    shared: {
      greeting: 'Hi',
      languageSwitcher: {
        de: 'German',
        en: 'English',
      },
      refreshApp: {
        title: 'New version available',
        description: 'New version of COMETUM is avalailable. Click UPDATE to get the latest version.',
        button: 'Update',
      },
      documentDialog: {
        cancel: 'Cancel',
        accept: 'I accept',
      },
      liquidity: 'Liquidity',
      marketplaceTeaser: {
        desc: 'Starte mit Deinem ersten Investment.',
        button: 'Jetzt investieren',
      },
      comingSoon: {
        title: 'Watchlist',
        desc: 'Coming soon.',
      },
      creditCard: {
        title: 'Your Reference Bank Account',
      },
      transactionCard: {
        offered: 'Finanzierungsphase läuft',
      },
    },
  },
  all: 'All',
  order_menu: 'Order Menu',
  order_success: 'Your order is successfully placed.',
  buy: 'Buy',
  sell: 'Sell',
  price: 'Price',
  make_buy_order: 'Make Buy Order',
  make_sell_order: 'Make Sell Order',
  make_order: 'Make Order',

  transfer: 'Transfer',
  transfer_success: 'Transfer successful',
  transfer_menu: 'Transfer Menu',
  send: 'Send',
  receive: 'Receive',
  wallet_to_debit: 'Wallet to debit',
  wallet_to_credit: 'Wallet to credit',
  your_wallet_address: 'Your Wallet Address',
  recipient_address: 'Recipient Address',
  enter_address: 'Enter Address',
  amount: 'Amount',
  make_transfer: 'Make Transfer',

  accountBalance: {
    totalBalance: 'Total Balance',
    digitalAssets: 'Digital Assets',
    euroAccount: 'EURO Account',
  },
  marketplaceTeaser: {
    title: 'Visit our Marketplace!',
    button: 'Invest now',
  },
  referral: {
    title: 'Invite a friend and receive 50€!',
  },
  depositInstructions: {
    title: 'Deposit Instructions',
    desc: 'Please make a transfer from your reference bank account',
  },
  assets: {
    formFields: {
      status: 'Status',
      title: 'Title',
      subTitle: 'Subtitle',
      description: 'Description',
      imageUrl: 'Image URL',
      valuation: 'Valuation',
      tokenSupply: 'Token Supply',
      tokenPrice: 'Token Price',
      factSheet: {
        condition: 'Condition',
        description: 'Description',
        color: 'Color',
        mileage: 'Mileage',
        modelYear: 'Model Year',
      },
    },
    create: 'Add Asset',
    edit: 'Edit Asset',
  },

  registration: {
    navButtons: {
      next: 'Next',
      back: 'Back',
      confirm: 'Confirm',
      finish: 'Login Now',
    },
    info: {
      1: {
        title: 'Large Variety of Exclusive Assets',
        desc: 'Access to a unique set of alternative assets such as vehicles and art pieces - preselected by our expert committee.',
      },
      2: {
        title: 'Superior Diversification',
        desc: 'Alternative assets are largely uncorrelated with traditional asset classes which enhances the risk-return profile of your portfolio.',
      },
      3: {
        title: 'Making the Illiquid Liquid',
        desc: 'We provide a secondary market that enables investors to manage their portfolio exposures with utmost flexibility.',
      },
    },
    create: {
      title: 'Create Account',
      desc: 'Please provide you email address and choose a safe password',
      fields: {
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'Email',
        tos: `I agree with COMETUM's`,
      },
      hints: {
        email: `You'll have to verify your email address`,
      },
    },
    confirm: {
      title: 'Confirm Your Registration',
      desc: (ctx) => `We sent you an email to ${ctx.named('email')} with a 6-digit verification code. Please enter it below.`,
    },
    success: 'Your registration was successful',
    resend: 'We have sent you an Email with a new code',
    cognitoMessages: {
      CodeMismatchException: 'Code not correct.',
      UsernameExistsException: 'An account with the given email already exists',
      LimitExceededException: 'Too many tries. Please try again later.',
      GenericException: 'Something went wrong. Please try again.',
      NotAuthorizedException: 'User cannot be confirmed. Current status is CONFIRMED',
    },
  },
  auth: {
    login: 'Login',
    register: 'Create Account',
    email: 'Email',
    password: 'Password',
    confirm_password: 'Password',
    forgot_password: 'Forgot Password',
    first_name: 'First Name',
    last_name: 'Last Name',
    sign_in: {
      desc: 'Sign in with your email address and your password',
      email_hint: 'Please provide a valid email address',
      password_hint: 'Minimum 6 characters, uppercase letters, lowercase letters, numbers and symbols',
    },
    cognitoMessages: {
      UserNotFoundException: `We couldn't find a user with your email address`,
      NotAuthorizedException: 'Your user name or password are incorrect',
      LimitExceededException: 'Too many tries. Please try again later.',
      GenericException: 'Something went wrong. Please try again.',
    },
  },
  forgotPassword: {
    requestCode: {
      title: 'Reset Password',
      desc: `We'll send you a verification code to your email address.`,
      submit: 'Request Code',
      back: 'Back',
    },
    submitCode: {
      title: 'Code Submit',
      desc: (ctx) => `We have sent you a 6-digit verification key to your email ${ctx.named('email')}. Please enter it below.<br>After that you can enter the password you will use to log in to COMETUM.`,
      submit: 'Submit Code',
      resend: 'Resend Code',
      error: 'Suspicious activity detected',
      passwordError: 'Your passwords do not match',
      newPassword: 'New Password',
      retypePassword: 'Retype new Password',
    },
    newPassword: {
      title: 'New Password',
      desc: 'Choose Your New Password',
      submit: 'Save',
    },
    cognitoMessages: {
      CodeMismatchException: 'Code not correct.',
      ExpiredCodeException: 'Your Code has expired. Please request a new one.',
      UserNotFoundException: `We couldn't find a user with your email address`,
      CodeRequestedSucessfully: 'Code requested successfully. Please check your inbox.',
      LimitExceededException: 'Too many tries. Please try again later.',
      GenericException: 'Something went wrong. Please start over.',
    },
  },
  notification: {
    close: 'Close',
  },
  connectivity: {
    offline: 'You appear to be offline',
    online: `You're back online`,
  },
  newDeals: {
    title: {
      vehicle: 'Vehicles',
      art: 'Artworks',
      realestate: 'Real Estate',
      crypto: 'Crypto',
    },
    button: {
      invest: 'Invest',
    },
    productInfo: {
      chip: 'Tokenized Security',
      fundingStage: 'Funding Stage',
      currentValuation: 'Current Valuation',
      estimatedReturn: 'Estimated Total Return p.a.',
      tokenSupply: 'Token Supply',
      tokenPrice: 'Price per Token',
      growthRate10y: '10 Year Growth Rate',
      condition: 'Condition',
      color: 'Color',
      mileage: 'Mileage',
      year: 'Year',
      description: 'Description',
      years: 'Year(s)',
      firstCard: {
        title: {
          Published: 'Soon available',
          Offered: 'Invest now',
          Financed: 'Already financed',
        },
        firstItem: 'Finanzierungsvolumen',
        secondItem: 'Mindestzeichnungssumme',
        thirdItem: 'Verfügbare Token',
        button: 'Investieren',
        secondTitle: 'Mein Investment',
        titleChip: 'ausstehend',
        fourthItem: 'Wert meiner Anteile',
        fifthItem: 'Anteile',
      },
      secondCard: {
        title: 'Rahmenbedingungen der tokenisierten Schuldverschreibung',
        firstItem: 'Zeichnungsfrist',
        secondItem: 'Laufzeitende',
        prognose: 'Prognose',
        thirdItem: 'Laufzeit - Gesamt',
        fourthItem: 'Historische Wertentwicklung p.a.',
        fifthItem: 'Durchschnittliche Gebühren p.a.',
        tooltipMessage: 'Historische Wertentwicklung vergleichbarer Anlagen. Wichtig: Die Wertentwicklung in der Vergangenheit ist kein zuverlässiger Indikator für eine künftige Wertentwicklung. Gesetzlicher Warnhinweis siehe unten.',
        tooltipMessageTwo: 'Bezogen auf den Ausgabebetrag. Details siehe Aufstellung der Kosten und Zuwendungen bei Zeichnung.',
      },
      moreDetails: {
        titleOne: 'Auf einen Blick',
        descriptionOne: 'Vom Rennwagen zum Straßensportwagen<br>Die Besonderheit des 300 SL (W198 I) gründete in vielen konstruktiven Details wie dem Gitterrohrrahmen, dem Flügeltürkonzept und nicht zuletzt dem ersten Motor mit Benzindirekteinspritzung.<br>Der Sieg in den fünf größten Rennveranstaltungen von 1952 gilt auch heute noch als einer der größten Rennerfolge eines Fahrzeugherstellers. Schnell kamen Bestellungen aus aller Welt und die Serienproduktion wurde beschlossen. Am 6. Februar 1954 wurde der Mercedes-Benz 300 SL der Welt vorgestellt.',
        descriptionTwo: 'Ein Traum mit Seltenheitscharakter<br>Insgeamt wurden nur 1.400 Coupés zum damals horrenden Preis von 29.000 DM gebaut. Zusammen mit dem 300 SL Roadster werden von der intern als W198 bezeichneten Baureihe bis 1963 insgesamt 3.258 Exemplare gebaut.',
        descriptionThree: 'Der Stoff, aus dem Legenden sind<br>Der Vater des 300 SL war Chefingenieur Rudolf Uhlenhaut, der schon seit den 30er Jahren bei Mercedes tätig war. Er hatte maßgeblichen Anteil an der Entwicklung der Mercedes-Benz Fahrzeuge nach dem zweiten Weltkrieg. Uhlenhaut war ein Multitalent, was sich unter anderem darin manifestierte, dass er auf der Rennstrecke genauso schnell unterwegs war wie seine Formel1-Fahrer. Dazu war er ein unbestrittener Meister im Bereich der Ingenieurskunst, so war der für den 300 SL typische Gitterrohrrahmen seine Idee. Die Besonderheit des Motors und die Benzindirekteinspritzung geht auf die Forschungsarbeiten von Hans Scherenberg zurück. Was wenige wissen: Der Designer des 300 SL ist Friederich Geiger.<br>Es ist die Melange aus Können und Wissen dieser drei Konstrukteure und Gestalter, die das Jahrhundertauto ausmachen.',
        titleTwo: 'Fakten über das Asset',
        titleThree: 'Über die Historie',
        descriptionFour: 'Von August 1954 bis Mai 1957 entstanden in Sindelfingen 1.400 Exemplare, davon 29 mit Leichtmetall-Aufbau und ein Versuchsfahrzeug mit Kunststoff-Karosserie. Das Einzelstück mit GFK-Karosserie ist an zwei Besonderheiten eindeutig zu identifizieren: Es verfügt über zusätzliche, auf den Vorderkotflügeln angeordnete Blinkleuchten, wie sie vom Typ 220 a bekannt sind, und hat Türen mit größeren Spaltmaßen, die nicht ganz bündig schließen. Die Produktion des 300 SL - und zeitgleich die des 190 SL - lief am 8. Februar 1963 in Sindelfingen aus. Dieses Datum markierte bei Daimler-Benz das Ende einer Epoche: Nachdem im März 1962 bereits die Produktion des Typ 300 eingestellt worden war, verschwand nun mit dem 300 SL auch das letzte Pkw-Modell mit separatem Rahmen aus dem Produktionsprogramm. (Quelle: Mercedes Benz Official Archive)',
        titleFour: 'Preisentwicklung im Kontext',
        descriptionFive: 'Einziger deutscher Herausforderer war der BMW 507. Der war zwar billiger, und zu seinen Käufern gehörte Elvis Presley, doch konnte er dem Erfolg des offenen 300 SL nichts entgegensetzen. Erst bei den Klassiker-Auktionen des 21. Jahrhunderts konnte der bayerische Roadster seinen schwäbischen Rivalen überholen. Allerdings haben die Preise für den 300 SL in den letzten Jahren ebenfalls raketengleich abgehoben. Seit 2012 verdreifachten sich dort die Preisforderungen für makellose 300 SL Roadster auf bis zu 1,5 Millionen Euro. Noch wertvoller sind naturgemäß 300 SL Roadster mit Glamourfaktor, und davon gibt es viele. Natalie Wood fuhr das Auto ebenso wie Romy Schneider, Sophia Loren, Gina Lollobrigida, der Aga Khan und der Schah von Persien. Hinzu kommen die vielen SL Roadster, die es zu einer eigenen Filmkarriere brachten – unter anderem setzten Robert Redford, Carol Reed, Francis Ford Coppola, Tim Burton oder Clint Eastwood den Wagen in ihrem Streifen ein. (Quelle: www.welt.de 2017)',
        titleFive: 'Verwahrung & Instandhaltung',
        descriptionSix: 'Den Wert von Alternativen Investments zu erhalten ist bei beweglichen Gegenständen um so kniffeliger, je wertvoller sie sind. Deshalb kommt diesem Thema ein wesentlicher Anteil am Erfolg eines Investments zu. Die richtige Lagerung, das professionelle Instandhalten aller Komponenten und eine angemessene Konservierung und Bewegung des Fahrzeuges, von professionellen Experten, ist werterhaltend - wenn nicht sogar wertsteigernd. Diesen Anforderungen wird COMETUM mit seinem Einlagerungskonzept stets gerecht und hat daher mit professionellen Partnern der Verwahrung höchste Priorität eingeräumt.',
        titleSix: 'Versicherung',
        descriptionSeven: 'Das Fahrzeug wird in einer gesicherten Lagerhalle in der Nähe von München gelagert. Das Fahrzeug ist vollversichert über den Betrag der Bewertung des Gutachters (vom 22.03.2022) hinaus. Neben dem Thema der Instandhaltung kommt der adäquaten Versicherung höchste Wichtigkeit zu. Neben preiswerten Lösungen hat selbstverständlich auch der Leistungsumfang, eines angemessenen Versicherungspaketes, höchtsen Einfluss auf Werterhalt und -steigerung. COMETUM arbeitet mit führenden Versicherungsunternehmen zusammen, welche sich auf Classic Cars / Oldtimer spezialisiert haben und ist hierdurch in der Lage dem hochpreisingen Wertgegenstand seine angemessene Sicherheitskonzeption angedeien zu lassen.',
        titleSeven: 'Expertenmeinung',
        descriptionEight: 'Als Grundsatz gilt: Gute Substanz ist das A und O. Unrestaurierte Flügeltürer gibt es aber nur noch äußerst selten. Daher gilt die genaue Prüfung der durch Fotos dokumentierten Restaurierung. Bei einem Auto in dieser Preisklasse sollte sie vorhanden sein.<br>Der Sechszylinder-Viertakt-Reihenmotor (Typ M 198) Motor mit der Benzin-Direkteinspritzung ist eine Pionierleistung. Wem es auf einen originalgetreuen Motor ankommt, sollte den Zustand genau prüfen. Bei leistungsgesteigerten Motoren ist besondere Vorsicht geboten.<br>Bei einem Sportwagen mit dieser Historie und Klasse bewegen sich die Preise in schwindelerregenden Höhen. Stand diesen Jahres kosten Exemplare mit Stahlkarosserie in gepflegtem Zustand rund 650.000 Euro. Im Oktober 2011 wurde auf der Artcurial-Auktion in Paris gerade ein SL 300, Baujahr 1955, für 531.380 Euro versteigert. Unrestaurierte 300 SL Coupés findet man kaum noch auf dem Oldtimer-Markt. Aber selbst Fluegeltuerer in mäßigen Zustand bewegen sich bei Preisen um die 310.000 Euro.<br>(Quelle: auto-motor-und-sport.de, abgerufen am 25.03.2022)',
        titleEight: 'Bewertung Gutachter',
        descriptionNine: 'Neuwertiges und bis ins Detail 100 % original erhaltenes Fahrzeug im Zustand der damaligen Erstauslieferung vom Hersteller ohne jegliche Standschäden. Sehr selten!<br>Fahrzeug mit einer leichten Patina, d.h. Originales Fahrzeug mit äußerst geringer Laufleistung, Fahrzeug nach Originalspezifikation restauriert in „Concours Conditions". Restauration soeben abgeschlossen. Fahrzeug der Spitzenklasse ohne fehlerhafte Aggregate oder unzeitgemäße Details. Guter Originalzustand mit geringen Zugeständnissen an das Alter des Fahrzeuges. Sach- und fachgerecht vor geraumer Zeit durchgeführte, sehr gute Komplettrestauration unter Verwendung originaler bzw. originalgetreuer Teile und Materialien Fahrzeug in fahrbereitem Gebrauchszustand mit unbedeutenden Mängeln an Optik, Technik oder Originalität. Keine Durchrostungen an Rahmen oder Karosserie. Keine sofortigen zur Durchführung der nächsten HU nach § 29 STVZO relevanten Arbeiten erforderlich, teilrestauriertes Fahrzeug. Fahrzeug in restaurierungsbedürftigem, verbrauchtem Zustand eventuell nicht fahrbereit, aber weitestgehend komplett. Zum Teil Mängel an Optik, Technik oder Originalität.<br>Der Marktwert für das vorstehend näher beschriebene Fahrzeug für den Zeitraum der Untersuchung am 27.01.2022 sowie unter Berücksichtigung der speziellen Marktlage im Winter 2021/22 beträgt € 120.000,00.<br>(Quelle: illustrativer Auszug aus einem aktuellen Gutachten, abgerufen am 25.03.2022)',
        titleNine: 'Dokumente',
        titleTen: 'Gesetzlicher Warnhinweis',
        descriptionTen: 'Die Informationen dieser Website/ dieses Dokumentes richtet/n sich aus rechtlichen Gründen ausschließlich an Personen die Ihren gewöhnlichen Aufenthalt oder Wohnsitz in der Bundesrepublik Deutschland haben.<br>Mit dem Erwerb einer tokenisierten Schuldverschreibung geht der Erwerber ein erhebliches Risiko ein, welches zum vollständigen Verlust des eingesetzten Vermögens führen kann. Die hier aufgeführten Informationen haben lediglich einen werblichen Charakter und stellen, in ihrer Art und Form, ausdrücklich keine Finanz- oder etwaig sonstige Anlageberatung dar. Eine auf die Verhältnisse des Anlegers abgestellte Anleger- und anlagegerechte Beratung wird durch die genannten Informationen in keinem Fall ersetzt. Die Emittentin weist nachdrücklich auf folgende Sachverhalte hin: Maßgeblich für die Beurteilung der tokenisierten Schuldverschreibung sind mithin ausschließlich die Informationen, welche aus dem Basisinformationsblatt und den Schuldverschreibungsbedingungen der Emittentin (COMETUM Classic Cars 01 GmbH & Co. KG) hervorgehen. Die vorgenannten Dokumente sind auf der Internetseite https://cometum.com/CCC-01 veröffentlicht und können dort kostenlos heruntergeladen werden.<br>Alle Informationen stellen weder eine Aufforderung zur Abgabe eines Angebotes zum Erwerb, noch ein Angebot zur Zeichnung oder zum Kauf der tokenisierten Schuldverschreibung der Emittentin dar.',
      },
    },
    waitingListCard: {
      title: 'There are more deals upcoming!',
      desc: 'Sign in to our waiting list to stay updated',
      button: 'Waiting List',
    },
  },
  settings: {
    knowledgeAndExperience: {
      title: 'Edit knowledge and experience',
      desc: 'Here you can view your knowledge and experience and make changes. Changes have no effect on your previous investments.',
      save: 'Save',
      reset: 'Reset',
      cancel: 'Cancel',
      apply: 'Apply Changes',
      success: 'Klassifikation als Investor aktualisiert',
      warningModal: {
        title: 'Nicht gespeicherte Änderungen',
        desc: 'Willst Du diese Seite wirklich verlassen und Deine Änderungen verwerfen?',
        yes: 'ja, Fortfahren',
        no: 'Abbrechen',
      },
      prokWarningModal: {
        title: 'Aktuelle Änderung der Kenntnisse & Erfahrungen beeinflusst Deinen Status als Professioneller Kunde. Willst Du mit dem Speichern fortfahren?',
        desc: 'Deine Kenntnisse & Erfahrungen wurden so angepasst, dass das Speichern eine Änderung Deiner Klassifizierung als Professioneller Kunde zur Folge hätte. Für die folgenden Geschäftsarten würdest Du ab sofort als Privatkunde klassifiziert werden:',
        list: '<ul><li>Investmentfonds</li><li>Staatsanleihen</li><li>Anleihen</li><li>Aktien</li><li>Kryptowährungen / Security Token</li><li>Nachrangdarlehen / Partizipationsdarlehen</li></ul>',
        yes: 'Ja, fortfahren',
        no: 'speichern Abbrechen',
      },
      changeProkModal: {
        title: 'Klassifizierung als Privatkunde',
        desc: 'Du hast Deine Kenntnisse & Erfahrungen fundamental geändert, sodass wir Deine Klassifizierung als Professioneller Kunde angepasst haben. Für die folgenden Geschäftsarten bist Du ab sofort als Privatkunde klassifiziert:',
        list: '<ul><li>Investmentfonds</li><li>Staatsanleihen</li><li>Anleihen</li><li>Aktien</li><li>Kryptowährungen / Security Token</li><li>Nachrangdarlehen / Partizipationsdarlehen</li></ul>',
        footer: 'Wenn Du gewisse Bedingungen erfüllst, hast Du die Möglichkeit, Dich als Professioneller Kunde neu zu klassifizieren.',
        yes: 'Okay',
      },
      downgradeProkModal: {
        title: 'Änderung der Klassifizierung als Professioneller Kunde',
        desc: 'Willst Du Deinen Status als Professioneller Kunde für die folgende/n Geschäftsart/en aufgeben und einen Status als Privatkunde beantragen?',
        list: '<ul><li>Investmentfonds</li><li>Staatsanleihen</li><li>Anleihen</li><li>Aktien</li><li>Kryptowährungen / Security Token</li><li>Nachrangdarlehen / Partizipationsdarlehen</li></ul>',
        yes: 'Jetzt beantragen',
        no: 'Abbrechen',
      },
      investorStatus: {
        title: 'Dein Status als Investor',
        desc: {
          private: 'Deine Geschäftsarten als Privatkunde',
          prok: 'Deine Geschäftsarten als Professioneller Kunde',
        },
        tooltip: 'Grundsätzlich stufen wir alle Kunden bei COMETUM als Privatkunden ein. Diese genießen dann grundsätzlich ein umfassendes Schutzniveau gemäß den Vorschriften des Wertpapierhandelsgesetzes (WpHG). Im Falle eines Antrags auf Einstufung (Klassifizierung) als Professioneller Kunde, gelten Unterschiede hinsichtlich dieses Schutzniveaus. Das bedeutet: Für Professionelle Kunden gilt das hohe Schutzniveau des Privatkunden dann nicht mehr.',
        list: '<ul><li>Investmentfonds</li><li>Staatsanleihen</li><li>Anleihen</li><li>Aktien</li><li>Kryptowährungen / Security Token</li><li>Nachrangdarlehen / Partizipationsdarlehen</li></ul>',
        partialUser: {
          InvestmentFund: 'Investmentfonds',
          GovernmentBonds: 'Staatsanleihen',
          Bond: 'Anleihen',
          Share: 'Aktien',
          CryptoCurrency: 'Kryptowährungen / Security Token',
          UnsecuredJuniorDebt: 'Nachrangdarlehen / Partizipationsdarlehen',
        },
        btn: {
          prok: 'klassifikation als Professioneller Kunde',
          private: 'privatkunde werden',
          reclassify: 'neu klassifizieren',
        },
        footer: {
          partOne: 'Wenn Du gewisse Bedingungen erfüllst, hast Du die Möglichkeit, Dich als Professioneller Kunde einstufen zu lassen.',
          partTwo: 'kannst Du im Detail sehen, was der Unterschied zwischen einem Privatkunden und einem Professionellen Kunden ist und was dies für Dein Schutzniveau und Deine Anlagefreiheit bedeutet.',
          link: 'Hier',
        },
      },
    },
    investorClassification: {
      title: 'Classification as a Professional Client',
      btn: {
        next: 'Next',
        back: 'Back',
        save: 'Become a professional customer now',
      },
      modal: {
        title: 'Nicht gespeicherte Änderungen',
        desc: 'Bist Du sicher, dass Du die Klassifizierung als Professioneller Kunde abbrechen willst?',
        yes: 'Yes, cancel',
        no: 'No',
      },
      conditions: {
        prok: {
          true: {
            title: 'Klassifikation möglich',
            desc: 'Aufgrund Deiner Angaben besteht für Dich die Möglichkeit, Dich als Professioneller Kunde einstufen zu lassen.',
            btn: 'Weiter',
          },
          false: {
            title: 'Klassifikation nicht möglich',
            desc: 'Gemäß den Vorschriften des Wertpapierhandelsgesetzes (WpHG) erfüllst Du nicht alle Kriterien, um eine Einstufung als Professioneller Kunde abschließen zu können. Natürlich kann sich das in der Zukunft ändern. Bis dahin genießt Du das Schutzniveau eines Privatkunden.',
            btn: 'Klassifikation beenden',
          },
        },
      },
    },
    profile: {
      title: 'Personal Data',
      list: {
        name: 'Name',
        phone: 'Phone',
        address: 'Address',
        birth: 'Date of birth',
        place: 'Place of birth',
        citizenship: 'Citizenship',
        tax: 'Tax identification number',
        request: 'Request an extract from my stored data',
      },
      listFooter: 'You will soon be able to edit your personal data here. If you want to make adjustments now, please contact our support.',
      secondListTitle: 'Your Data',
    },
    security: {
      list: {
        email: 'E-Mail Adresse',
        password: 'Password',
      },
      password: {
        desc: (ctx) => `With this password you can log in to COMETUM with the email ${ctx.named('email')}.`,
        submit: 'Save password',
        success: 'Password changed successfully',
      },
    },
  },
  contract: {
    title: 'Vertragsunterlagen & Bedingungen',
    description: 'Wir haben Deine Daten übernommen und die Vertragsunterlagen für Dich aufgelistet. Erst mit dem abschließenden Klick werden die Vertragsunterlagen an uns übermittelt.<br><br>Bitte lies Dir alle Vertragsunterlagen sorgfältig durch und nimm diese zur Kenntnis.',
    checkboxText: 'Ich gebe folgende Erklärung ab:<br><br><ul><li>Ich habe das Dokument “Zeichnungsschein” erhalten.</li><li>Ich habe das Dokument “Verbraucherinformationen samt Widerrufsbelehrung” erhalten.</li></ul>',
    contract_agreement: {
      pdf: {
        title: 'Zeichnungsschein',
      },
    },
    consumer_information: {
      pdf: {
        title: 'Verbraucherinformationen Emittent samt Widerrufsbelehrung',
      },
    },
  },
  risks: {
    title: 'Risiken',
    description: (ctx) => `Vor Abschluss Deiner Investition solltest Du die angebotenen Dienstleistungen unseres Partners ${ctx.named('assetIssuer')} verstehen.`,
    checkboxText: 'Ich gebe folgende Erklärungen ab:<br><br><ul><li>Ich habe die Risiken verstanden und zur Kenntnis genommen.</li><li>Ich habe das Dokument “Risiken inkl. Interessenkonflikt” erhalten.</li><li>Ich verstehe, dass dieses Wertpapier mit erheblichen Risiken verbunden ist und zum vollständigen Verlust des eingesetzten Vermögens führen kann.</li></ul>',
    risks: {
      pdf: {
        title: 'Risiken inkl. Interessenkonflikt',
      },
    },
  },
  costs: {
    and: {
      benefits: {
        title: 'Kosten und Zuwendungen',
        description: '',
        checkboxText: 'Ich gebe folgende Erklärung ab:<br><br><ul><li>Ich habe die Informationen zu den “Kosten und Zuwendungen” gelesen und nehme diese zur Kenntnis.</li></ul>',
        costs_and_benefits: {
          pdf: {
            title: 'Kosten und Zuwendungen',
          },
        },
      },
    },
  },
  key: {
    information: {
      document: {
        title: 'Basisinformationsblatt',
        description: 'Hinweis: Sie haben das Recht, die kostenlose Aushändigung eines Papier-Exemplars zu verlangen.',
        checkboxText: 'Ich gebe folgende Erklärung ab:<br><br><ul><li>Ich habe das Basisinformationsblatt gelesen und nehme dieses zur Kenntnis.</li></ul>',
        key_information_document: {
          pdf: {
            title: 'Basisinformationsblatt',
          },
        },
      },
    },
  },
  custody: {
    title: 'Verwahrbedingungen',
    description: '',
    checkboxText: 'Ich folgende Erklärungen ab:<br><br><ul><li>Ich habe die Verwahrbedingungen gelesen und nehme diese zur Kenntnis.</li><li>Ich bin damit einverstanden, dass die COMETUM GmbH unverzüglich nach Beendigung des Nutzungsvertrages alle meine Daten innerhalb des COMETUM IT-Systems an den Emittenten des Investment-Token weitergeben wird. Die Erteilung dieser Einwilligung ist freiwillig. Ich kann sie jederzeit mit Wirkung für die Zukunft widerrufen. Allerdings ist mir bewusst, dass in diesem Fall keine Weitergabe meiner Daten an den Emittenten erfolgen wird. Weitere Einzelheiten finden sich in der Datenschutzerklärung von COMETUM.</li></ul>',
    custody: {
      pdf: {
        title: 'Verwahrbedingungen',
      },
    },
  },
  emission: {
    terms: {
      title: 'Schuldverschreibungsbedingungen',
      description: '',
      checkboxText: 'Ich gebe folgende Erklärung ab:<br><br><ul><li>Ich habe die Schuldverschreibungsbedingungen gelesen und nehme diese zur Kenntnis.</li></ul>',
      emission_terms: {
        pdf: {
          title: 'Schuldverschreibungsbedingungen',
        },
      },
    },
  },
  effecta: {
    title: 'Effecta GmbH',
    description: '',
    checkboxText: 'Ich gebe folgende Erklärungen ab:<br><br><ul><li>Ich habe die AGB der Effecta GmbH gelesen und nehme diese zur Kenntnis.</li><li>Ich habe die Verbraucherinformationen der Effecta GmbH gelesen und nehme diese zur Kenntnis.</li></ul>',
    tos: {
      pdf: {
        title: 'Effecta GmbH AGB',
      },
    },
    consumer_information: {
      pdf: {
        title: 'Effecta GmbH Verbraucherinformationen',
      },
    },
  },
  consent: {
    declaration: {
      prok: {
        title: '',
        description: '',
        checkboxText: 'Ich gebe folgende Erklärungen ab:<br><br><ul><li>Ich beantrage eine Hochstufung als Professioneller Kunde für die obengenannte/n Geschäftsart/en und verzichte auf das Schutzniveau eines Privatanlegers.</li><li>Ich bestätige, dass ich durch das Dokument “Hinweis Professioneller Kunde” von COMETUM eindeutig auf die rechtlichen Folgen der Änderung der Einstufung hingewiesen wurde.</li><li>Ich bestätige, dass ich das Dokument “Hinweis Professioneller Kunde” erhalten und dessen Inhalt zur Kenntnis genommen habe.</li></ul>',
        information_prok: {
          pdf: {
            title: 'Hinweis Professioneller Kunde',
          },
        },
        acknowledgement_information_prok: {
          pdf: {
            title: 'Bestätigung der Kenntnisnahme des Dokuments “Hinweis Professioneller Kunde”',
          },
        },
      },
    },
  },
  marketplace: {
    title: 'Investments',
    numResult: {
      singular: (ctx) => `${ctx.named('amount')} result`,
      plural: (ctx) => `${ctx.named('amount')} results`,
    },
    lifecycleTitle: {
      Teased: 'Bald verfügbar',
      Published: 'Bald verfügbar',
      Offered: 'Jetzt investieren',
      Financed: 'Finanzierungsphase beendet',
    },
    categories: 'Investments nach Kategorien',
    productCard: {
      comingSoon: 'Asset wird bald freigeschaltet',
      preCountdown: 'Verfügbar in',
    },
  },
  mobile: {
    title: 'COMETUM MOBILE',
    subtitle: 'BALD VERFÜGBAR',
    desc: 'Nutze bis zum Launch der Mobilversion<br>unsere Desktop-Version über den Browser.',
    btn: 'mehr informationen',
  },
  eventTypes: {
    Registration: 'Registration’',
    ProkClassification: 'Einstufung als Professioneller Kunde',
    ProkReclassification: 'Neuklassifizierung als Professioneller Kunde',
    ProkDowngrade: 'Einstufung als Privatkunde',
    Onboarding: 'COMETUM Kontoeröffnung',
    Subscription: 'Investition',
    TransactionDeposit: 'Einzahlung',
    TransactionWithdrawal: 'Auszahlung',
  },
  reconnectModal: {
    title: 'Bist Du noch da?',
    desc: (ctx) => `Deine Verbindung wurde unterbrochen, da Du für einige Zeit inaktiv warst. In ${ctx.named('time')} Minuten wirst Du automatisch abgemeldet. Willst Du Dich erneut verbinden?`,
    yes: 'erneut verbinden',
    no: 'abmelden',
  },
  'ae4ce35a-a2c2-42c8-bc26-6ee8643c2683': {
    productInfo: {
      moreDetails: {
        titleOne: 'Auf einen Blick',
        descriptionOneTitle: 'Wenn man Sterne trinken könnte',
        descriptionOne: 'Eine schöne, einfach zu schön klingende Geschichte rankt sich um die Erfindung des ersten Champagners. So soll doch ein Mönch in Frankreich, geweckt durch eine, durch Gärung mit lautem Knall geplatzte Flasche, bei genaueren Verkostung der betroffenen Flaschen ausgerufen haben: “Brüder kommt schnell - ich trinke Sterne...!“. Diese märchenhafte Begebenheit klingt so fantastisch wie aktuell, so ist doch der Geschmack von Champagner für viele Menschen noch heute ein glückhafter Moment. Champagner ist und war stets ein wertvolles Genussmittel und hat nie an seiner Strahlkraft eingebüßt und ist begehrt wie nie. Neben seiner Funktion als “Gefäß” für Luxus, Prestige, Reichtum und gesellschaftlichen Aufstieg ist er auch ein Wertspeicher. Besondere Jahrgänge können als tokenisiertes Wertpapier auch einen ungeahnten Renditebeitrag zu einem diversifizierten Portfolio leisten.',
        descriptionTwoTitle: 'Wie die Magie sich bahnbricht?',
        descriptionTwo: 'Die Herstellungsmethode ist in der Regel die "Méthode Traditionelle" (auch Traditionelle Flaschengärung, klassische Flaschengärung) früher auch Champagner-Methode genannt. Traditionell findet die zweite Gärung, in der der Grund­wein zum Sekt wird, in Einzel­flaschen statt. Hierzu werden dickwandige Sektflaschen verwendet und meist mit einem Kronenkorken verschlossen. Dieser Qualitätsschaumwein muss in Deutschland mindestens neun Monate auf der Hefe liegen. Danach liegen diese Flaschen meist mindestens 36 Monate auf der Hefe, die sog. Grande Réserve’s sogar über 120 Monate. Danach werden die einzelnen Flaschen gerüttelt und das Hefedepot wird als schockgefrorener Pfropf, mit einem lauten Knall, gezielt entfernt (dekoriert) und final mit einer Agraffe verkorkt.',
        titleTwo: 'Fakten über das Asset',
        titleThree: 'Über die Historie',
        descriptionFour: 'Dom Ruinart stammte aus einer bürgerlichen Familie von Tuchhändlern aus der Champagne. Während seiner Handelsreisen in Europa erkannte er das Zukunftspotential für die Produktion von Champagner. Er erkannte die wachsende Begeisterung an den aristokratischen Höfen, welche durch den Champagner ausgelöst wurde. Dieses Wissen und seine Vision zum Champagner vermittelte er seinem Bruder Nicolas Ruinart.<br><br>Dieser musste auf den königlichen Erlass vom 25. Mai 1728 warten, um seine Pläne verwirklichen zu können. Mit diesem Erlass erlaubte der König den Transport von Wein in Flaschen. Vor diesem Zeitpunkt durfte der Wein nicht in Flaschen, sondern nur in Fässern transportiert werden, was für Champagner undenkbar war.<br><br>Die ersten Sendungen Champagner gingen an Tuchhändler, was man heute als Werbegeschenke bezeichnen würde. Wie sein Onkel zwanzig Jahre zuvor stellte Nicolas Ruinart bald fest, dass das Weingeschäft einträglicher war als das Tuchgeschäft. In den Häfen ersetzten die Champagnerkörbe mit Flaschenwein auf den Schiffen bald die Stoffballen und die Tuchrollen. Das Geschäft lief so gut, dass ab 1735 der Verkauf von Champagner die einzige Tätigkeit des Hauses Ruinart war.<br><br>Heute gehört Ruinart zum Luxusartikelkonzern LVMH.',
        descriptionFourFooter: 'Quelle: wikipedia',
        titleFour: 'Preisentwicklung im Kontext',
        descriptionFive: 'Investieren in Wein kann sich lohnen. Doch der Markt ist derzeit intransparent. Das behauptet Ron Freund, Diplom-Sommelier und Vorstandsvorsitzender von Wine Stocks. Mit dieser Online-Plattform soll der Weinmarkt transparenter gestaltet werden. Seinen Angaben nach verfolgt Wine Stocks jede wichtige Weinauktion weltweit. Und die Daten werden täglich mit bis zu 50.000 neuen Datensätzen  von über 100 Online- und Präsent-Auktionen aktualisiert. Die 26 Millionen Auktionspreise bis in das Jahr 1996 beziehen sich auf mehr als 58.000 Weine von über 9000 Weingütern.',
        descriptionFiveFooter: 'Quelle: HANDELSBLATT 2013',
        titleFive: 'Verwahrung & Versicherung',
        descriptionSix: 'Um eine bestmögliche Wertentwicklung zu gewährleisten, erstellt COMETUM einen umfangreichen und sicheren Verwahrungs- und Sicherungsplan. Alle Gegenstände der “Precious-Goods-Kategorie” werden in einem versicherten, bewachten und bestens eingerichteten Verwahrobjekt gehalten. Um Schäden zu vermeiden, gehören dazu selbstverständlich auch regelmäßige eine Sicherheitsrevision von erfahrenen Experten. Dabei bleiben wir stets kosteneffizient und auf eine Wertsteigerung bedacht. Neben den besten Rahmenbedingungen für die Verwahrung ist für Dich auch ein umfassender Schutz vor Beschädigung und beispielsweise Feuer wichtig - kein Problem, denn auch dafür hat COMETUM gesorgt.',
        titleSix: 'Bewertungen von Weinkritikern',
        descriptionSeven: 'Der lange weltweit tonangebende Weinkritiker war der Amerikaner Robert Parker. Bewertete er einen Wein hoch, bewirkte dies einen signifikanten Effekt auf den Verkaufspreis. Weine mit Parkers Maximalnote 100 sind bei Sammlern immer noch sehr gesucht. Häufig werden indes bei den Fassproben 100 Punkte vergeben, die im Nachhinein wieder nach unten korrigiert werden (z. B. Bordeaux Jahrgang 2000). Parker verkaufte sein mittlerweile von verschiedenen Spezialisten herausgegebenes Magazin «Wine Advocate» bereits 2012 und zog sich 2019 gänzlich zurück.<br><br>Als einflussreichste Weinkritiker neben Parkers «Wine Advocate» werden derzeit die Journalisten Neal Martin und Antonio Galloni (beide bei «Vinous») angesehen; vielfach bevorzugen sie leichtere, elegantere Weine als Robert Parker, der eine Präferenz für massivere Gewächse hatte. Auch Jancis Robinson und Allen Meadows (Burgund) sind gewichtige Stimmen. Wer hohe Noten liefert, wird öfter zitiert und bekommt mehr Publizität. Aus diesem Grund überrascht es nicht, dass seit einiger Zeit exzellente Bewertungen inflationär vergeben werden und der Verkaufseffekt von Wein-Bewertungen abnimmt. Das von Parker eingeführte 100-Punkte-Bewertungssystem scheint sich trotzdem international immer mehr durchzusetzen.',
        titleSeven: 'Dokumente',
        titleEight: 'Gesetzlicher Warnhinweis',
        descriptionEight: 'Die Informationen dieser Website/ dieses Dokumentes richtet/n sich aus rechtlichen Gründen ausschließlich an Personen, die ihren gewöhnlichen Aufenthalt oder Wohnsitz in der Bundesrepublik Deutschland haben. Mit dem Erwerb einer tokenisierten Schuldverschreibung geht der Erwerber ein erhebliches Risiko ein, welches zum vollständigen Verlust des eingesetzten Vermögens führen kann. Die hier aufgeführten Informationen haben lediglich einen werblichen Charakter und stellen, in ihrer Art und Form, ausdrücklich keine Finanz- oder etwaige sonstige Anlageberatung dar. Eine auf die Verhältnisse des Anlegers abgestellte Anleger- und anlagegerechte Beratung wird durch die genannten Informationen in keinem Fall ersetzt. Die Emittentin weist nachdrücklich auf folgende Sachverhalte hin: Maßgeblich für die Beurteilung der tokenisierten Schuldverschreibung sind mithin ausschließlich die Informationen, welche aus dem Basisinformationsblatt und den Schuldverschreibungsbedingungen der Emittentin (Cometum Art UG (haftungsbeschränkt) & Co. KG) hervorgehen. Die vorgenannten Dokumente sind auf der Internetseite www.cometum.com/issuers/cmt-art veröffentlicht und können dort kostenlos heruntergeladen werden.<br>Ein Sekundärmarkt für tokenisierte Wertpapiere ist in Zukunft vorgesehen. Alle Informationen stellen weder eine Aufforderung zur Abgabe eines Angebotes zum Erwerb, noch ein Angebot zur Zeichnung oder zum Kauf der tokenisierten Schuldverschreibung der Emittentin dar.',
      },
    },
  },
  'b5ca686d-fdb3-424f-b218-b594e86d59a4': {
    productInfo: {
      moreDetails: {
        titleOne: 'Auf einen Blick',
        descriptionOneTitle: 'Eine perfekte Ikone - die Magie von “matching numbers”',
        descriptionOne: 'Der legendäre und visionäre Gründer Ferry Porsche ist mit dem Satz zu zitieren: "Damit etwas Bestand haben kann, muss es einzigartig sein - und gleichzeitig so universell, dass es jedem gefallen kann. Das Einzige, was universell ist, ist die Suche nach dem Sinn."<br>Einzigartigkeit, ein Unikat eben, etwas das niemand sonst besitzt, zumal wenn es auch Andere begehrenswert finden, erfüllt viele Voraussetzungen ein geeigneter Wertspeicher zu sein. Insbesondere in Zeiten zunehmender Inflation.',
        descriptionTwoTitle: 'Was aber macht ein Fahrzeug einzigartig?',
        descriptionTwo: 'Ganz klar: Authentizität, Qualität und eine vollständig transparente Historie. Bei Fahrzeugen gibt auch die Angabe der sogenannten "matching numbers" als einen Hinweis auf diesen einzigartigen Status. Dies bedeutet nicht viel mehr, als dass die wesentlichen Teile des Fahrzeugs (Getriebe, Motor, Fahrgestell) noch in der originalen Werkskonfiguration erhalten sind - das Fahrzeug ist dann meist zudem noch in seinem Originalzustand. Im Kontrast dazu stehen sogenannte "Frame-Off"-Fahrzeuge, welche völlig zerlegt, zu einem neuen, zumeist auch sehr guten, Fahrzeug zusammengestellt wurden - oft mit Originalteilen, aber eben eher wie eine sogenannte "Marriage" bei Kunstwerken.<br>Der hier beschriebene Porsche 911E mit Ölklappe von 1972 besitzt diese "magischen matching numbers" und ist daher eine "Ikone im Originalzustand" - selten, begehrenswert, authentisch und ikonisch zugleich.',
        secondDescriptionTwoTitle: '50 Jahre und frisch wie am Auslieferungstag',
        secondDescriptionTwo: '"Frisch wie am Auslieferungstag" - vielleicht macht auch genau dieser Zustand ein derartiges Fahrzeug, wie es COMETUM hier vorstellt, zum idealen Wertspeicher. Neben den reinen technischen Fakten sind es sicher die Substanz des Fahrzeugs, welche diesem 911er zugrunde liegt. Aufbau und Karosserie entsprechen weitestgehend dem Auslieferungszustand, die es zu einem Wertspeicher machen können. Zudem ist die Fahrzeug-Historie vollständig nachvollziehbar dokumentiert. Alle diese Fakten sind mehrfach gutachterlich bestätigt worden.',
        thirdDescriptionTwoTitle: 'Der 911 ist der Porsche schlechthin',
        thirdDescriptionTwo: 'Erst am 14.05.2022 schrieb die Süddeutsche Zeitung (SZ) in ihrem Artikel "Sportwagen - Einer wie keiner": "Der Porsche 911, er ist im Jahr 2022 vielleicht so begehrt wie noch nie. Krisenfest. Und vielleicht versteckt sich dort, wo Menschen ein halbes Leben von einem Gegenstand träumen, ja doch so etwas wie ein tieferer Sinn."<br>Nun ermöglicht Dir COMETUM die seltene Chance, Teil an einem 911er der "Extraklasse" zu haben - ganz einfach: Geschrieben auf die Blockchain, tokenisiert und verbrieft gehört Dir ein mittelbarer Teil dieser Ikone. Und Du bist damit in guter Gesellschaft, denn im SZ-Artikel heißt es weiter: "Der Sportwagen, der immer da war. Den sie alle mal fuhren: Kate Moss, Steve Jobs, Jerry Seinfeld."',
        titleTwo: 'Fakten über das Asset',
        titleThree: 'Über die Historie',
        descriptionFour: 'Für nach 1980 geborene war der Elfer eigentlich immer schon da. Er veränderte sich über die Zeit natürlich immer mal wieder. Er wurde immer wieder technisch angepasst und verbessert. Aber seit mehr als 60 Jahren nach seinem Debüt ist er immer noch da und - historisch oder neu - begehrt wie eh und je. Von dem hier vorgestellten Modell, dem 911 E, welcher im Dezember 1972 in Stuttgart gebaut und in den USA ausgeliefert wurde, sind insgesamt circa 1.200 Stück gebaut worden. Er war speziell für den Markt in USA, Kanada und Europa vorgesehen. Die E-Serie des Porsche 911 wurde im Modelljahr 1972 produziert und umfasste die Karosserievarianten Coupé und Targa. Im Gegensatz zum nachfolgenden Modelljahr (F-Serie) war der Öltank der Trockensumpfschmierung in das Fahrzeugheck verlegt; dazu war das Fahrzeug mit einer "Ölklappe" im Seitenteil hinten rechts ausgerüstet. Im Modelljahr 1973 wurde in den Modellen 911 T, 911 E und 911 S der Motor dann letztmals mit dem Hubraum von 2.341 cm3 verbaut. Heute stellen die E- und F-Serien die letzten Modellvarianten des Porsche 911 mit den klassischen (schmalen) Stoßfängern des Urmodells dar.',
        titleFour: 'Preisentwicklung im Kontext',
        descriptionFive: '"Der Wert von vielen historischen Elfern hat sich in den vergangenen 15 Jahren verdreifacht." (SZ 14.05.2022, S.60).<br><br>In diesem Kontext steht auch die transparente, durch Gutachten und Eigentümerkette, nachgewiesene Preishistorie des hier beschriebenen Fahrzeugs. So bescheinigen die tatsächlichen Kaufpreise und Wertgutachten folgende Wert-Abfolge: 44.700 € (2010); 70.000 € (2010); 93.000 € (2013); 140.000 € (2022) sowie ein bereits 2019 gutachterlich bestätigter Wiederbeschaffungswert von 223.000 €.',
        titleFive: 'Verwahrung, Instandhaltung & Versicherung',
        descriptionSix: 'Um eine bestmögliche Wertentwicklung zu gewährleisten, erstellt COMETUM einen umfangreichen und sicheren Verwahrungs- und Instandhaltungsplan. Das Fahrzeug wird in einem versicherten, bewachten und bestens eingerichteten Garagenpark, eines Spezialisten für zeitgenössische und historische Fahrzeuge, verwahrt und instandgehalten. Um Standschäden zu vermeiden, gehören dazu selbstverständlich auch regelmäßige Bewegungsfahrten von erfahrenen Experten. Dabei bleiben wir stets kosteneffizient und auf eine Wertsteigerung bedacht. Neben den besten Rahmenbedingungen für die Verwahrung ist für Dich auch ein umfassender Schutz vor Beschädigung und beispielsweise Feuer wichtig - kein Problem, denn auch dafür hat COMETUM gesorgt.',
        titleSix: 'Bewertung Gutachter',
        descriptionSeven: 'Insbesondere bei weitestgehend original erhaltenen Fahrzeugen können die einschlägigen Zustandseinstufungen mit "Schulnoten" nicht sinnvoll angewendet werden. Aus der Sicht des Sachverständigen ist es deshalb gerechtfertigt, bei dem begutachteten Fahrzeug auf eine diesbezügliche Zustandseinstufung zu verzichten. Auf Grundlage der vorgenannten Ausführungen wird der Wiederbeschaffungswert für ein tatsächlich vergleichbares, vom qualifizierten Fachhandel kurzfristig zu beschaffendes Fahrzeug, derzeit geschätzt auf: 223.000,00 EUR ggf. zzgl. anteiliger MwSt. aus Differenzbesteuerung.',
        titleSeven: 'Dokumente',
        titleEight: 'Gesetzlicher Warnhinweis',
        descriptionEight: 'The aforementioned "shares" do not represent a direct ownership of a tangible asset but are represented by a security tokenised on a public blockchain. For legal reasons, the information on the website / in this article is directed exclusively at persons who have their habitual residence or domicile in the Federal Republic of Germany. With the acquisition of a tokenised debt security, the acquirer assumes a considerable risk, which may lead to the complete loss of the invested assets. The information provided here is of a promotional nature only and, in its nature and form, expressly does not constitute financial or any other investment advice. The above information is in no way a substitute for investor and investment advice tailored to the investor\'s circumstances. The Issuer expressly draws attention to the following facts: The information contained in the basic information notice and the Terms and Conditions of the Issuer (COMETUM Classic Cars UG & Co. KG (haftungsbeschränkt)). The aforementioned documents are published on the website Cometum | Digital Private Banking  and can be downloaded there free of charge.A secondary market for tokenised securities is envisaged in the future. All information constitutes neither a solicitation of an offer to purchase nor an offer to subscribe for or purchase the Issuer\'s tokenised debt securities.',
      },
    },
  },
  'f4cfa74f-a415-4bac-8411-251076a49948': {
    productInfo: {
      moreDetails: {
        titleOne: 'Auf einen Blick',
        descriptionOneTitle: 'Geschliffene Eleganz und zeitloser Wertspeicher',
        descriptionOne: `Wer kennt nicht den Songtext vom Star der 50er Jahre, Marilyn Monroe : "Diamonds are a girl's best friend!”. Die Faszination für diese “besonderen Steine” ist wohl schon mit dem ersten Fund in Indien, vermutlich 400 Jahre vor Christus, verbunden. Denn schon damals schrieb man Diamanten magische Fähigkeiten zu. Diese “Magie” ist heute kaum gemindert, auch wenn sie sich heute mehr auf die “Magie” des Werterhaltes, der Wertsicherung und der Wertsteigerung bezieht. Wirkliche Zauberei ist das natürlich nicht. Das detaillierte Wissen um die Fakten, die einen solchen Stein als werthaltig definieren, ist von nöten.`,
        descriptionTwoTitle: 'Was aber macht einen Diamanten einzigartig?',
        descriptionTwo: 'Niedrige Korrelation mit sonstigen Märkten ist wohl die einzigartige Stärke eines Diamanten im Portfolio. Der stagnierende Abbau von Diamanten und eine ungebrochen hohe Nachfrage, nicht nur aus Asien, lassen die Preise stetig steigen. In Farbe und Form außergewöhnliche Diamanten erzielen immer wieder Rekordergebnisse und bescheren Investoren damit regelmäßig hohe Renditen. COMETUM legt jedoch hohen Wert auf eine stete Fungibilität und Marktgängigkeit des Diamanten-Portfolios. Der liquide Markt für Diamanten exzellenter Qualität macht den hier durch eine tokenisierte Schuldverschreibung repräsentierten Diamanten zu einem Investment mit schwankungsarmer Charakteristik. Die unbedingte Prämisse der marktüblichen 4C-Kriterien, in Bezug auf “Carat”, “Color”, “Cut” und “Clarity”, ist für uns selbstverständlich.',
        secondDescriptionTwoTitle: 'Portfoliobestandteil Diamant',
        secondDescriptionTwo: 'Gepaart mit tiefem Wissen über Finanzen, Märkte und neuen Technologien ist COMETUM in der Lage, als Anteil an einem tokenisierten Wertpapier, mit wenigen Klicks, solch eine faszinierende Preziose jedem Portfolio hinzuzufügen. Man muss nicht einem “Diamantenrausch”, wie nach dem Erstfund von Diamanten im 18. Jahrhundert in Brasilien, erliegen, um zu erkennen, welches Potential ein qualitative herausragender Diamant in einem Portfolio entfalten kann. Insbesondere in Zeiten steigender, nachhaltiger Inflation, ist ein tendenziell unkorreliertes Asset wie beispielsweise ein Diamant ein besonders geeigneter Portfoliobestandteil.',
        thirdDescriptionTwoTitle: 'Hart wie Diamant - Unsere Auswahlkriterien',
        thirdDescriptionTwo: 'Der dem Angebot der tokenisierten Schuldverschreibung zugrunde liegende Diamant ist ein ganz besonderes Exemplar, denn er erfüllt alle Qualität darstellenden Kriterien für ein gutes Investment:',
        titleTwo: 'Fakten über das Asset',
        titleThree: 'Herkunft',
        descriptionFour: 'Während Bodenschätze für andere rohstoffreiche Länder in Afrika eher ein Fluch waren, sind die Diamanten in Botswana durch die seit Jahren kluge Politik ein Segen für das Land. Vor einem halben Jahrhundert gehörte es noch zu den 25 ärmsten der Welt, jetzt zur oberen Mittelschicht. In der Demokratischen Republik Kongo kommen die gefragten Rohstoffe wie Kobalt zum Batteriebau nur einer kleinen Elite zugute, das ölreiche Nigeria versinkt in Korruption. Die Steine aus Botswana gelten als ethisch einwandfrei und sollen dem Land noch etwa ein Vierteljahrhundert Reichtum bescheren.',
        titleFour: 'Preisentwicklung im Kontext',
        descriptionFive: 'Im März 2022 schrieb die ZEIT-ONLINE mit Berufung auf die Quelle dpa: “Nach einer Studie der Unternehmensberatung Bain mit dem World Diamond Center in Antwerpen stiegen die Preise für Rohdiamanten im vergangenen Jahr um 21 Prozent. Der Umsatz der Branche legte demnach um 29 Prozent auf das Rekordniveau von 84 Milliarden Dollar zu.”. Da die bekannten weltweiten Diamantenreserven weiterhin abgebaut werden, während die weltweite Nachfrage nach Diamanten steigt, dürfte sich in den kommenden Jahren eine Lücke zwischen Angebot und Nachfrage nach Diamanten ergeben. Bis zum Jahr 2050 wird ein Angebotsdefizit von etwa 278 Millionen Karat Diamanten weltweit prognostiziert.',
        titleFive: 'Verwahrung & Versicherung',
        descriptionSix: 'Um eine bestmögliche Wertentwicklung zu gewährleisten, erstellt COMETUM einen umfangreichen und sicheren Verwahrungs- und Versicherungsplan. Dabei bleiben wir stets kosteneffizient und auf eine Wertsteigerung bedacht. Neben den besten Rahmenbedingungen für die Verwahrung ist für Dich auch ein umfassender Schutz vor Beschädigung oder Verlust wichtig - kein Problem, denn auch dafür hat COMETUM gesorgt.',
        titleSix: 'Bewertung Gutachter',
        descriptionSeven: 'Alle unsere Diamanten sind durch eines der weltweit führenden Gemmologischen Institute zertifiziert und für ihre Qualität ausgezeichnet. Abgesehen von den 4 C’s, welche als Qualitätsindikator dienen und sich auf den Schliff (Cut), das Gewicht (Carat), die Farbe (Color) und die Reinheit (Clarity) beziehen, gilt unser besonderes Augenmerk auch noch der Symmetrie, Politur und der Fluoreszenz. Mittels dieser eindeutigen Qualitätsmerkmale und COMETUM’s direkten Beziehungen zu Händlern in Antwerpen, gelingt es uns, die sichersten und begehrtesten Diamanten für unsere Investoren zu sourcen.',
        titleSeven: 'Dokumente',
        titleEight: 'Gesetzlicher Warnhinweis',
        descriptionEight: 'Die Informationen dieser Website/ dieses Dokumentes richtet/n sich aus rechtlichen Gründen ausschließlich an Personen, die ihren gewöhnlichen Aufenthalt oder Wohnsitz in der Bundesrepublik Deutschland haben. Mit dem Erwerb einer tokenisierten Schuldverschreibung geht der Erwerber ein erhebliches Risiko ein, welches zum vollständigen Verlust des eingesetzten Vermögens führen kann. Die hier aufgeführten Informationen haben lediglich einen werblichen Charakter und stellen, in ihrer Art und Form, ausdrücklich keine Finanz- oder etwaige sonstige Anlageberatung dar. Eine auf die Verhältnisse des Anlegers abgestellte Anleger- und anlagegerechte Beratung wird durch die genannten Informationen in keinem Fall ersetzt. Die Emittentin weist nachdrücklich auf folgende Sachverhalte hin: Maßgeblich für die Beurteilung der tokenisierten Schuldverschreibung sind mithin ausschließlich die Informationen, welche aus dem Basisinformationsblatt und den Schuldverschreibungsbedingungen der Emittentin (COMETUM Art UG (haftungsbeschränkt) & Co. KG) hervorgehen. Die vorgenannten Dokumente sind auf der Internetseite http://www.cometum.com/issuers/cmt-art veröffentlicht und können dort kostenlos heruntergeladen werden.<br><br>Ein liquider Sekundärmarkt für tokenisierte Wertpapiere existiert derzeit nicht, ist jedoch künftig vorgesehen. Alle Informationen stellen weder eine Aufforderung zur Abgabe eines Angebotes zum Erwerb, noch ein Angebot zur Zeichnung oder zum Kauf der tokenisierten Schuldverschreibung der Emittentin dar.',
      },
    },
  },
  assetType: {
    Art: 'Art',
    Vehicle: 'Vehicle',
    PrivateEquity: 'Private Equity',
    RealEstate: 'Real Estate',
    Diamond: 'Diamond',
    Wine: 'Wine',
    Crypto: 'Crypto',
  },
  assetSubtype: {
    Oldtimer: 'Oldtimer',
    Youngtimer: 'Youngtimer',
    Supercar: 'Supercar',
    Painting: 'Painting',
    Graphic: 'Graphic',
    Sculpture: 'Sculpture',
    Plastic: 'Plastic',
    Photography: 'Photography',
    ContemporaryArt: 'Contemporary Art',
    VentureCapital: 'Venture Capital',
    Fund: 'Fund',
    Apartment: 'Apartment',
    RealEstate: 'Real Estate',
    Funds: 'Funds',
    Diamond: 'Diamond',
    Wine: 'Wine',
    NonFungibleToken: 'Non-Fungible Token',
    Coin: 'Coin',
  },
};
